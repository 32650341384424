
import VueRecaptcha from "vue-recaptcha";
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { date } from "quasar";

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

interface ticketData {
  street: string;
  street_number: string;
  hint: string;
  message: string;
  since: any;
  until: any;
  mascot: any;
  security: any;
  in_charge: string | null;
  in_charge_phone: string | null;
}

@Component({
  components: {
    FilePond,
    VueRecaptcha
  }
})
export default class SendTicketForm extends Mixins(FormValidator) {
  protected formData: ticketData = {
    street: "",
    street_number: "",
    hint: "",
    message: "",
    since: null,
    until: null,
    mascot: null,
    security: null,
    in_charge: null,
    in_charge_phone: null
  };

  disable_button = false;

  private streets: any[] = [];
  private options_street: any = [];
  private postulacion_fecha_minima: number = Date.now();
  private postulacion_seleccion_minima: number = Date.now();
  private postulacion_fecha_maxima: number = 0;

  options_select: object[] = [
    { label: "Si", value: "1" },
    { label: "No", value: "2" }
  ];

  private my_locale = {
    days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    firstDayOfWeek: 1
  };

  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }

  private onVerify(response: any) {
    this.sendTicketAttemp();
  }

  private onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "accent",
      position: "top",
      timeout: 5000
    });
  }

  private dateQuestionSince() {
    this.updateUntilDate();
    (this.$refs as any).qDateProxySince.hide();
  }

  private dateQuestionUntil() {
    (this.$refs as any).qDateProxyUntil.hide();
  }

  /*
  private limitSince(date: any) {
    let limiter;
    if (this.formData.until) {
      let dateEnd = this.formData.until.split("-");
      let endObject = new Date(
        +dateEnd[2],
        (dateEnd[1] as any) - 1,
        +dateEnd[0]
      );
      limiter = Date.parse(date as any) <= Date.parse(endObject as any);
    }
    return limiter;
  }
  */

  private limitSince(date: string) {
    let dateMills = Date.parse(date)
    return dateMills >= this.postulacion_fecha_minima && dateMills <= this.postulacion_fecha_maxima;
  }

  private updateUntilDate() {
    this.postulacion_seleccion_minima = Date.parse(this.formData.since.split('-').reverse().join('-'))
    if (this.formData.since && this.formData.since > this.formData.until)
      this.formData.until = null;
  }

  private limitUntil(date: string) {
    let dateMills = Date.parse(date);
    return dateMills >= this.postulacion_seleccion_minima && dateMills <= this.postulacion_fecha_maxima;
  }

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  protected dateFormat(date: string) {
    return (
      date.split("")[6] +
      date.split("")[7] +
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[3] +
      date.split("")[4] +
      "-" +
      date.split("")[0] +
      date.split("")[1]
    );
  }

  private sendTicketAttemp() {
    //
    if (
      Date.parse(this.formData.since as any) >
      Date.parse(this.formData.until as any)
    ) {
      return this.notify(
        "La fecha de termino no puede ser menor a la fecha de inicio"
      );
    }
    //
    this.disable_button = true;
    if (!this.areAllFilesOK()) {
      this.notify(this.$t("notification.problem_with_files") as string);
      return (this.disable_button = false);
    }

    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.notify(this.$t("notification.check_captcha") as string);
      return (this.disable_button = false);
    }

    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }
    if (adjuntos_empty) {
      adjuntos = null;
    }

    var flag = true;
    if (flag) {
      this.$axios
        .post(
          "inspeccions/solicitud",
          {
            id_servicio: this.$store.state.ticket.service.id,
            tipo: Number(this.$store.state.ticket.motive), //motivo de solicitud
            rut: this.$store.state.logged
              ? this.$store.state.user.rut
              : this.$store.state.ticket.guest_id,
            tipo_documento: this.$store.state.logged
              ? this.$store.state.user.document_type
              : this.$store.state.ticket.guest_document_type,
            adjunto: adjuntos,
            mensaje: this.formData.message,
            direccion: {
              calle: this.formData.street,
              numero: this.formData.street_number,
              aclaratoria: this.formData.hint
            },
            fecha_inicio: this.dateFormat(this.formData.since).concat(
              "T00:00:00Z"
            ),
            fecha_termino: this.dateFormat(this.formData.until).concat(
              "T23:59:59Z"
            ),
            mascota: this.formData.mascot,
            seguridad: this.formData.security,
            a_cargo: this.formData.in_charge,
            a_cargo_telefono:
              this.formData.in_charge_phone &&
              this.formData.in_charge_phone != ""
                ? this.formData.in_charge_phone
                : null,
            dispositivo: navigator.userAgent
          },
          {
            headers: {
              Authorization: this.$store.state.user.auth_token
                ? this.$store.state.user.auth_token
                : null,
              Captcha: captcha
            }
          }
        )
        .then(response => {
          let ticket = {
            id: response.data.id_ticket,
            message: response.data.mensaje,
            date: response.data.fecha,
            step: 4,
            adjuntos: response.data.adjunto ? response.data.adjunto : null
          };
          this.$store.dispatch("submitRequestFinished", ticket);
        })
        .catch(error => {
          let message = "Ha ocurrido un error al generar su ticket";
          switch (error.response.data.error.message) {
            case "persona_no_data":
              message =
                "Ha ocurrido un error. Sus datos como vecino están incompletos";
              break;
            case "save_ticket_error":
              message =
                "Se ha producido un error en el procesamiento de su solicitud";
              break;
            case "empty_mascota":
              message = "Es necesario completar el campo mascota";
              break;
            case "empty_seguridad":
              message = "Es necesario completar el campo seguridad";
              break;
            case "invalid_adjuntos":
              message = "El archivo adjunto proporcionado no es válido";
              break;
            case "invalid_direccion":
            case "create_direccion_error":
              message =
                "Ha surgido un inconveniente al establecer la dirección";
              break;
          }
          this.notify(message);
        })
        .finally(() => {
          this.disable_button = false;
        });
    } else {
      this.disable_button = false;
      this.notify("Rellene los campos obligatorios");
    }
  }

  private get canAutoFillAdress() {
    if (this.$store.state.logged) {
      if (this.$store.state.user.adress.district == this.comuna_cliente) {
        return true;
      }
    } else {
      if (
        this.$store.state.ticket.guest_adress_district == this.comuna_cliente
      ) {
        return true;
      }
    }
    return false;
  }

  private autoFillAdress() {
    if (this.$store.state.logged) {
      this.formData.street = this.$store.state.user.adress.street;
      this.formData.street_number = this.$store.state.user.adress.number;
      this.formData.hint = this.$store.state.user.adress.hint;
    } else {
      this.formData.street = this.$store.state.ticket.guest_adress_street;
      this.formData.street_number = this.$store.state.ticket.guest_adress_number;
      this.formData.hint = this.$store.state.ticket.guest_adress_hint;
    }
    return false;
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) return;
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "PUT",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };

  private getStreets() {
    this.$q.loading.show();
    this.$axios
      .get("calles/v2/listar")
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {
        this.notify("Error al obtener las calles");
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private humanDate(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return date_time.split(" ")[0];
  }

  private getLimitDates() {
    this.$axios
      .get("inspeccion_fechas/limite", {
        headers: {
          Authorization: this.$store.state.user.auth_token
        }
      })
      .then(response => {
        if (response.data) {
          if (response.data.minimo) {
            let responseDataMinMills = Date.parse(response.data.minimo);
            this.postulacion_fecha_minima = (Date.now() >= responseDataMinMills)?
              Date.now() :
              responseDataMinMills;
            this.postulacion_seleccion_minima = this.postulacion_fecha_minima;
          }
          if (response.data.maximo)
            this.postulacion_fecha_maxima = Date.parse(response.data.maximo);
        }
      })
      .catch(error => {
        let message = "Error al obtener las fechas límite";
        this.notify(message);
      })
      .finally(() => {});
  }

  private mounted() {
    this.getStreets();
    this.getLimitDates();
  }
}
